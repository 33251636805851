<template>
<div class="fili">
      <div class="title">营养监测列表</div>
      <div class="filter">
        <a-row>
          <!-- <a-col :span="12">
            <div
              class="item"
              style="border-right: 1px dotted #e7e7eb;"
            >
              <div class="title">阶段</div>
              <div class="value">
                <span class="active">小学</span>
                <span>初中</span>
                <span>高中</span>
              </div>
            </div>
          </a-col> -->
          <a-col :span="24">
            <div class="item">
              <div class="title">部门</div>
              <div class="value">
                <span v-for="item in schoolGrades"
                  :key="item"
                  :class="{active:selectedGrade===item}"
                  @click="filterHandler(item)"
                >{{item}}</span>
              </div>
            </div>
          </a-col>
        </a-row>
      </div>
      <div class="fun">
        <search placeholder="请输入子部门名称" @searchKeyChange="loadData" ref="search"></search>
      </div>
      <div class="list">
        <a-row>
          <template v-for="item in data" >
            <a-col :span="8" :key='item._id'>
            <div
              class="item" style="cursor:pointer;"
              @click="goClassNutrition(item)"
            >
              <div class="bd">
                <div class="text">
                  <div class="class ellipsis-l1">{{item.name}}</div>
                  <div class="cell">
                    <div class="title">负责人：</div>
                     <div class="value ellipsis-l1"><a-tooltip slot="suffix" :title="item.teachers">{{item.teachers}}</a-tooltip></div>
                  </div>
                  <div class="cell">
                    <div class="title">人数：</div>
                    <div class="value"><b>{{item.statistics?item.statistics.total:0}}</b></div>
                  </div>
                </div>
                <div class="chart">
                  <div
                    :id="item._id"
                    style="width: 80px;height:80px;"
                  ></div>
                  <div class="text">健康(<b>{{item.statistics?item.statistics.healthRate:100}}%</b>)</div>
                </div>
              </div>
              <div class="fd">
                <div class="con">
                  <div class="cell">
                    <div class="title">超量摄入：</div>
                    <div class="value fprimary"><b>{{item.statistics?item.statistics.nExcess:0}}</b></div>
                  </div>
                  <div class="progress">
                    <a-progress
                      :percent="item.statistics?item.statistics.excessRate:0"
                      size="small"
                      strokeColor="#f8ac30"
                    />
                  </div>
                </div>
                <div class="con">
                  <div class="cell">
                    <div class="title">摄入不足：</div>
                    <div class="value fred"><b>{{item.statistics?item.statistics.nInsufficient:0}}</b></div>
                  </div>
                  <div class="progress">
                    <a-progress
                      :percent="item.statistics?item.statistics.insufficientRate:0"
                      size="small"
                      strokeColor="#fc0d1b"
                    />
                  </div>
                </div>
              </div>
            </div>
          </a-col>
          </template>
        </a-row>
        <div class="pages">
          <a-pagination
            @change="loadData()"
            v-model="pagination.current"
            :total="pagination.total"
            :pageSize="pagination.pageSize"
            show-less-items
          />
        </div>
      </div>
    </div>
</template>

<script>
import { mapGetters, mapState, mapActions } from 'vuex'
import { all } from '@/business/enums'
import search from '@/components/common/Search'
import apis from '@/business/apis'
import moment from 'moment'
export default {
  inject: ['eventBus'],
  components: {
    search
  },
  data () {
    return {
      data: [],
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0
      },
      selectedGrade: all,
      selectedDate: moment()
    }
  },
  computed: {
    ...mapState('school', [
      'currentSchool'
    ]),
    ...mapGetters('school', [
      'schoolGrades'
    ])
  },
  methods: {
    ...mapActions('nutrition', [
      'saveCurrClass'
    ]),
    async goClassNutrition (item) {
      item.date = this.selectedDate
      await this.saveCurrClass(item)
      this.$router.push({
        name: 'ClassNutritionDetail'
      })
    },
    filterHandler (item) {
      this.selectedGrade = item
      this.loadData()
    },
    async loadData () {
      let query = '?schoolId=' + this.currentSchool._id + '&pageSize=9&current=' + this.pagination.current + '&date=' + this.selectedDate.unix()
      if (this.selectedGrade !== all) {
        query += ('&grade=' + this.selectedGrade)
      }
      if (this.$refs.search && this.$refs.search.searchKey) {
        query += ('&keyword=' + this.$refs.search.searchKey)
      }
      const response = await this.$http.get(apis.nutrition.classesNutrition + query)
      query = '?schoolId=' + this.currentSchool._id + '&pageSize=100000' // + '&role=teacher'
      const teachers = await this.$http.get(apis.schoolAdmin.list + query)
      this.data = response.body.classes
      this.pagination = response.body.pagination

      this.data.forEach(item => {
        if (response.body.statistics[item._id]) {
          item.statistics = response.body.statistics[item._id]
          item.statistics.excessRate = 0
          item.statistics.insufficientRate = 0
          if (item.statistics.total > 0) {
            item.statistics.excessRate = Math.round(item.statistics.nExcess / item.statistics.total * 100)
            item.statistics.insufficientRate = Math.round(item.statistics.nInsufficient / item.statistics.total * 100)
          }
          item.statistics.healthRate = 100 - item.statistics.excessRate - item.statistics.insufficientRate
        }
        this.createChart(item)

        const list = teachers.body.list.filter(teacher => teacher.groups && teacher.groups.indexOf(item._id) !== -1)
        item.teachers = ''
        list.forEach((teacher, index) => {
          item.teachers = index === 0 ? teacher.real_name : item.teachers + ',' + teacher.real_name
        })
      })
    },
    createChart (item) {
      const unHealth = item.statistics ? item.statistics.nExcess + item.statistics.nInsufficient : 0
      const health = item.statistics ? item.statistics.total - unHealth : 0
      setTimeout(() => {
        const chart = this.$echarts.init(document.getElementById(item._id))
        const colorList = ['#4bdd4d', '#fc0d1b']
        const option = {
          tooltip: {
            trigger: 'item',
            formatter:
                "<b style='font-size:18px;color:#ffce0a;font-family:DIN Alternate;'>{c}</b><span style='color:#ffce0a;'>人</span>({d}%)"
          },
          series: [
            {
              type: 'pie',
              radius: ['85%', '100%'],
              avoidLabelOverlap: false,
              label: {
                show: false
              },
              labelLine: {
                show: false
              },
              itemStyle: {
                normal: {
                  color: function (params) {
                    return colorList[params.dataIndex]
                  }
                }
              },
              data: [{ value: health }, { value: unHealth }]
            }
          ]
        }
        chart.setOption(option)
      }, 0)
    },
    dateChangeHandle (data) {
      this.selectedDate = data
      this.loadData()
    }
  },
  mounted () {
    this.eventBus.$on('nutrition-index-date-change', this.dateChangeHandle)
    this.loadData()
  },
  beforeDestroy () {
    this.eventBus.$off('nutrition-index-date-change', this.dateChangeHandle)
  }
}
</script>
