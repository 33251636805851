<template>
<div class="total">
    <div class="item">
      <div class="bd">
        <div class="icon">
          <img src="~@/assets/images/icon-school1.png" />
        </div>
        <div class="tenu">
          <div class="text">总人数</div>
          <div class="num"><b>{{total}}</b><span>人</span></div>
        </div>
      </div>
    </div>
    <div class="item">
      <div class="bd">
        <div class="icon">
          <img src="~@/assets/images/icon-school2.png" />
        </div>
        <div class="tenu">
          <div class="text">超量摄入</div>
          <div class="num"><b class="fprimary">{{nExcess}}</b><span>人</span></div>
        </div>
      </div>
    </div>
    <div class="item">
      <div class="bd">
        <div class="icon">
          <img src="~@/assets/images/icon-school3.png" />
        </div>
        <div class="tenu">
          <div class="text">摄入不足</div>
          <div class="num"><b class="fred">{{nInsufficient}}</b><span>人</span></div>
        </div>
      </div>
    </div>
    <div class="item">
      <div class="bd">
        <div class="icon">
          <img src="~@/assets/images/icon-school7.png" />
        </div>
        <div class="tenu">
          <div class="text">未用餐</div>
          <div class="num"><b>{{total-nDined}}</b><span>人</span></div>
        </div>
      </div>
    </div>
    <div class="item">
      <div class="bd">
        <div class="icon">
          <img src="~@/assets/images/icon-school8.png" />
        </div>
        <div class="tenu">
          <div class="text">已用餐</div>
          <div class="num"><b>{{nDined}}</b><span>人</span></div>
        </div>
      </div>
    </div>
      <!-- <a-row>
        <a-col :span="8">
          <div class="item">
            <div class="bd">
              <div class="icon"><img src="~@/assets/images/icon-school1.png" /></div>
              <div class="tenu">
                <div class="text">总人数</div>
                <div class="num"><b>{{total}}</b><span>人</span></div>
              </div>
            </div>
          </div>
        </a-col>
        <a-col :span="8">
          <div class="item">
            <div class="bd">
              <div class="icon"><img src="~@/assets/images/icon-school2.png" /></div>
              <div class="tenu">
                <div class="text">超量摄入</div>
                <div class="num"><b>{{nExcess}}</b><span>人</span></div>
              </div>
            </div>
          </div>
        </a-col>
        <a-col :span="8">
          <div class="item">
            <div class="bd">
              <div class="icon"><img src="~@/assets/images/icon-school3.png" /></div>
              <div class="tenu">
                <div class="text">摄入不足</div>
                <div class="num"><b>{{nInsufficient}}</b><span>人</span></div>
              </div>
            </div>
          </div>
        </a-col>
      </a-row> -->
</div>
</template>
<script>
import apis from '@/business/apis'
import { mapState } from 'vuex'
import moment from 'moment'
export default {
  inject: ['eventBus'],
  data () {
    return {
      total: 0,
      nExcess: 0,
      nInsufficient: 0,
      nDined: 0
    }
  },
  computed: {
    ...mapState('school', [
      'currentSchool'
    ])
  },
  methods: {
    async loadData (selectedDate) {
      const query = '?schoolId=' + this.currentSchool._id + '&date=' + selectedDate.unix()
      const respose = await this.$http.get(apis.nutrition.statistics + query)
      this.total = respose.body.total
      this.nExcess = respose.body.nExcess
      this.nInsufficient = respose.body.nInsufficient
      this.nDined = respose.body.nDined
    }
  },
  created () {
    this.eventBus.$on('nutrition-index-date-change', this.loadData)
    this.loadData(moment())
  },
  beforeDestroy () {
    this.eventBus.$off('nutrition-index-date-change', this.loadData)
  }
}
</script>
