<template>
  <div class="student-nutrition-detect">
    <div class="date">选择日期：<a-date-picker size="large" :allowClear='false' v-model="selectedDate" @change="onDateChange" :disabled-date="disabledDate" /></div>
    <statistics></statistics>
    <class-list></class-list>
  </div>
</template>

<script>
import statistics from '@/components/nutritionMonitor/Statistics'
import classList from '@/components/nutritionMonitor/ClassList'
import moment from 'moment'
export default {
  inject: ['eventBus'],
  components: {
    statistics,
    classList
  },
  data () {
    return {
      selectedDate: moment()
    }
  },
  methods: {
    onDateChange (data) {
      this.eventBus.$emit('nutrition-index-date-change', data)
    },
    disabledDate (date) {
      const now = moment()
      if (date <= now && date > now.subtract(15, 'days')) return false
      return true
    }
  }
}
</script>

<style lang="less">
.student-nutrition-detect {
  .date {
    margin-bottom: 24px;
  }
  .total {
    display: flex;
    border: 1px solid #e7e7eb;
    background: #fff;
      border-radius: 6px;
    .item {
      flex: 1;
      line-height: 1;
      position: relative;
      &::after {
        content: "";
        position: absolute;
        top: 20px;
        right: 0;
        bottom: 20px;
        border-right:1px dotted #e7e7eb;
        width: 1px;
      }
      &:last-child{
        &::after{
          display: none;
        }
      }
      .bd {
        padding: 28px 16px;
        display: flex;
        .icon {
          width: 40px;
          height: 40px;
          margin-right: 12px;
          img {
            width: 100%;
          }
        }
        .tenu {
          .num {
            margin-top: 8px;
            margin-bottom: -3px;
            b {
              font-size: 28px;
            }
            span {
              font-size: 12px;
              font-weight: normal;
              margin-left: 4px;
            }
          }
        }
      }
      .fd {
        border-top: 1px solid #e7e7eb;
        display: flex;
        .con {
          padding: 16px;
          flex: 1;
          display: flex;
          align-items: center;
          border-right: 1px solid #e7e7eb;
          &:last-child {
            border-right: none;
          }
          .num {
            font-weight: bold;
            font-size: 16px;
          }
        }
      }
    }
  }
  .fili {
    margin-top: 24px;
    border: 1px solid #e7e7eb;
    background: #fff;
    border-radius: 6px;
    padding: 0 16px;
    > .title {
      font-size: 20px;
      padding: 16px 0 12px;
    }
    .filter {
      border-radius: 2px;
      border: 1px solid #e7e7eb;
      padding: 8px 0;
      .item {
        padding: 6px 20px;
        display: flex;
        align-items: center;
        .title {
          font-weight: bold;
          margin-right: 24px;
        }
        .value {
          span {
            display: inline;
            margin-right: 24px;
            &:hover,
            &.active {
              color: #4283fc;
              cursor: pointer;
            }
          }
        }
      }
    }
    .fun {
      margin-top: 16px;
      display: flex;
      align-items: center;
      .search {
        .ant-input {
          height: 44px;
        }
        .anticon {
          font-size: 18px;
        }
      }
    }
    .list {
      margin: 4px -12px 0;
      .item {
        border: 1px solid #e7e7eb;
        border-radius: 6px;
        margin: 12px;
        cursor: pointer;
        .bd {
          padding: 12px 16px;
          display: flex;
          align-items: center;
          .text {
            flex: 1;
            max-width: 180px;
            .class {
              font-size: 20px;
              font-weight: bold;
            }
            .cell {
              margin-top: 4px;
              display: flex;
              align-items: center;
              .value {
                max-width: 120px;
              }
            }
          }
          .chart {
            margin-left: 21px;
            position: relative;
            .text {
              position: absolute;
              left: 0;
              right: 0;
              top: 50%;
              margin-top: -8px;
              text-align: center;
              font-size: 12px;
            }
          }
        }
        .fd {
          border-top: 1px solid #e7e7eb;
          display: flex;
          .con {
            flex: 1;
            padding: 12px 16px;
            border-right: 1px solid #e7e7eb;
            &:last-child {
              border-right: none;
            }
            .cell {
              display: flex;
              .value {
                font-weight: bold;
                font-size: 16px;
              }
            }
            .progress {
              margin: -8px 0 -4px;
            }
          }
        }
      }
      .pages {
        padding: 4px 12px 16px;
        text-align: center;
      }
    }
  }
}
</style>
